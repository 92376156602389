.main {
    @apply pt-8 grid max-w-[1440px] mx-auto xl:grid-cols-[minmax(50px,_65%)_minmax(50px,_35%)] 
    lg:grid-cols-[minmax(50px,_65%)_minmax(50px,_35%)] md:grid-cols-[minmax(50px,_65%)_minmax(50px,_35%)]
    xl:px-40 xl:py-10 lg:px-20 lg:py-10 md:px-20 md:py-10;
}

.parent {
    @apply flex text-center  mt-8 text-xl font-Inter font-normal text-[#b4b4b4] xl:text-start lg:text-start md:text-start
    xl:pr-20 lg:pr-16 md:pr-16 xl:text-2xl lg:text-2xl md:text-xl px-4;
}

.bt {
    @apply transition-all duration-500 py-3 px-5 rounded-2xl font-Inter text-xl font-normal text-white shadow-xl
    text-center bg-size-150 bg-pos-0 hover:bg-pos-100 bg-gray-500 hover:bg-gray-600;
}